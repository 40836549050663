import '../styles/blog.css'

import * as React from 'react'

import { Link, graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import { SidebarAvatarQuery } from '../../graphql-types'

const MeowVc: React.FC = () => {
  const data = useStaticQuery<SidebarAvatarQuery>(query)
  const imgData = data?.fileName?.childImageSharp?.gatsbyImageData
  return (
    <div
      className="meow text-white text-shadow text-center bg-color w-screen h-screen bg-cover flex flex-col bg-center items-center justify-center align-middle"
      style={{ backgroundImage: "url('/meowvc.jpg')" }}
    >
      <h1>meow.vc</h1>
      <h2>The Accelerator For Cats Speed: We invest in founders who move fast.</h2>
      <h3>Agility:</h3>
      <p>Startups need to react to a changing world, we invest in founders who live for this. </p>
      <h3>Stealth:</h3>
      <p>
        We invest in founders who can perfectly sneak up on a competitor several times their size and leave their foe bloodied and broken
        before they even know whats happening.
      </p>
      <h3>Tenacity</h3>
      <p>Never let the ball of string get away!</p>
      <div className="avatar-image pt-6">
        <Link to="/">
          {imgData && <GatsbyImage image={imgData as any} alt="" />}
          <br />
          Max Cantor
        </Link>
      </div>
    </div>
  )
}

export default MeowVc

export const query = graphql`
  query MeowvcAvatar {
    fileName: file(relativePath: { eq: "headshot.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, height: 400, layout: CONSTRAINED)
      }
    }
  }
`
